import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';


export class Apply_now extends Component {
    render() {

        return (
            <main>
                <Helmet>
                    <title>Apply Now For 2024 - Rostov State Medical University</title>
                    <meta name="description" content="Dear aspirants here is the online application for the 2024 academic year, interesting students can apply online to get direct admission in RostSMU, Russia." />
                    <meta name="robots" content="noindex, follow" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="article" />
                    <link rel="canonical" href="https://rrecrostov.com/apply-now" />
                    <meta property="og:title" content="Apply Now For 2024 - Rostov State Medical University" />
                    <meta property="og:description" content="Dear aspirants here is the online application for the 2024 academic year, interesting students can apply online to get direct admission in RostSMU, Russia." />
                    <meta property="og:url" content="https://rrecrostov.com/" />
                    <meta property="og:site_name" content="Rostov State Medical University, Russia" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>

                <section id="page-banner" class="pt-105 pb-110 bg_cover a1" data-overlay="8">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="page-banner-cont">
                                    <h2>Apply Now For 2024-25</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                                            <li class="breadcrumb-item active" aria-current="page">Apply Now For 2024-25</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section id="contact-page" class="pt-90 pb-120 gray-bg">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-7">
                                <div class="contact-from mt-30">
                                    <div class="main-form pt-45">
                                        <div class="section-title">
                                            <h2>Apply Now For 2024-25</h2>
                                        </div>
                                        <form name="f1" method="post" id="f1" action="https://app.todesign.in/react/rrecrostov.com/apply.php">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="name" id="name" type="text" placeholder="Your name" data-error="Name is required." required="required" />

                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="email" id="email" type="email" placeholder="Email" data-error="Valid email is required." required="required" />

                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="phone" id="phone" type="text" placeholder="Phone" data-error="Phone is required." required="required" />

                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="city" id="city" type="text" placeholder="City" data-error="City is required." required="required" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="state" id="state" type="text" placeholder="State" data-error="State is required." required="required" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="country" id="country" type="text" placeholder="Country" data-error="Country is required." required="required" />

                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="pcb" id="pcb" type="text" placeholder="PCB % (secondary school marks)" data-error="PCB % (secondary school marks) required." required="required" />
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="neet" id="neet" type="text" placeholder="NEET score (for Indian students only)" data-error="NEET score (for Indian students only) required." required="required" />
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-md-6">
                                                    <div className="form-group">

                                                        <select id="desired" name="desired" placeholder="Desired course*" type="text" required="required" className="form-control bg-white"   >
                                                            <option value=" selected">Desired course</option>
                                                            <option value="Medicine (MBBS)" >Medicine (MBBS)</option>
                                                            <option value="Dentistry (BDS)" >Dentistry (BDS)</option>
                                                            <option value="Pharmacy" >Pharmacy</option>
                                                            <option value="Pediatrics" >Pediatrics</option>
                                                            <option value="Post Graduation" >Post Graduation</option>
                                                        </select>

                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-md-6">
                                                    <div className="form-group">

                                                        <select id="medium" name="medium" placeholder="Medium of Instruction*" type="text" required="required" className="form-control bg-white">
                                                            <option value=" selected" className="form-group">Medium of Instruction</option>
                                                            <option value="English Medium" >English Medium</option>
                                                            <option value="Russian Medium" >Russian Medium</option>
                                                        </select>
                                                    </div>
                                                </div>


                                                <div class="col-md-12">
                                                    <div class="single-form form-group">
                                                        <textarea name="message" id="message" placeholder="Message" data-error="Please,leave us a message." required="required"></textarea>

                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="single-form">
                                                        <button type="submit" name="submit" id="submit" class="main-btn">Send</button>
                                                    </div>
                                                </div>


                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5  bg-orange text-center pt-50 pb-50 mt-50">
                                <h4><strong>Call for more details<br /> about the university, admission, processing, fees at RostSMU, Russia</strong></h4>
                                <p><strong><a href="tel:+91-7042284508" target="_blank">+91-7042284508</a>, <a href="tel:+917042284509" target="_blank">+91-7042284509</a>  <br /> Toll-free: <a href="tel:+1800-572-5827" target="_blank">1800-572-5827</a> (For Indian Students)<br />
                                    For International Students: <a href="tel:79604430042" target="_blank">+79604430042</a><br />
                                    E-mail: <a href="mailto:rrec.educonsultants@gmail.com">contact@rrecrussia.com</a>, <a href="mailto:rrec.educonsultants@gmail.com">rrec.educonsultants@gmail.com</a></strong></p>
                                <p><strong>Students or parents can write to us on WhatsApp also: <br/>+<a href="https://wa.me/79515371133" target="_blank">7-9515371133</a></strong></p>

                            </div>




                        </div>
                    </div>
                </section>

            </main>




        )
    }

}

export default Apply_now;