import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';


export class Rostov_state_medical_university extends Component {
  render() {

    return (
      <main>
        <Helmet>
          <title>Rostov State Medical University - Turning MBBS Dream Abroad Into Reality</title>
          <meta name="description" content="Admission procedure 2024: A prospective student has to apply through our official admission partner:(Reliable Russian Educational Consultants) Call 18005725827" />
          <meta name="robots" content="index, follow" />
          <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
          <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
          <link rel="canonical" href="https://rrecrostov.com/rostov-state-medical-university/" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Admission Procedure - Rostov State Medical University, Russia" />
          <meta property="og:description" content="Admission procedure 2024: A prospective student has to apply through our official admission partner:(Reliable Russian Educational Consultants) Call 18005725827" />
          <meta property="og:url" content="https://rrecrostov.com/" />
          <meta property="og:site_name" content="Rostov State Medical University, Russia" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>

        <section id="page-banner" class="pt-105 pb-110 bg_cover a1" data-overlay="8">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="page-banner-cont">
                  <h2>Rostov state medical university</h2>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="#">Home</a></li>
                      <li class="breadcrumb-item active" aria-current="page">Rostov state medical university</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>




        <section class="pt-105 pb-110">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="privacy-desc">
                  <iframe width="100%" height="315" src="https://www.youtube.com/embed/EqdUuJZG2Fo" title="Rostov State Medical University | Russia | Official" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                  <h1>Rostov State Medical University – Turning MBBS Dream Abroad Into Reality</h1>
                  <h2>Rostov State Medical University – Unleashing Great Opportunity to study MBBS in 2024</h2>

                  <p>When you dream big, it always requires a reliable platform, isn’t it? If you are aiming for pursuing MBBS in Russia then Rostov State Medical University (RostSMU or RostGMU) will be an ideal place to be. The quality and standard of Rostov State Medical University are comparatively higher and far better than many Western universities. However, the cost of medical education is still very feasible. For this reason, a massive number of Indian students whose ambition is to pursue MBBS abroad are opting for Rostov State Medical University. </p>
                  <p>In India, every year more than 16 lacs students appear for the MBBS exam to qualify for just 40 thousand seats. They have to battle fierce competition in India to qualify for their dream goal. Though, not all can go through this exam. This leads many capable students looking for an MBBS course in Russia when they find it unfortunate to get through in India. Rostov State Medical University is a great option for such aspirants who want to continue their graduation or post-graduation in medicine from Russia.   </p>
                  <p>RREC (Reliable Russian Educational Consultants) is the official consultant for RostSMU. Visit the website <a href="https://rrecrostov.com/">www.rrecrostov.com</a> for detailed information on RostSMU admission.</p>

                  <h3>Rostov State Medical University – Quick Fact check</h3>
                  <table class="table" cellpadding="8" cellspacing="8">
                    <tbody>
                      <tr>
                        <th>Name of the Institute</th>
                        <th>Rostov  State Medical University</th>
                      </tr>
                      <tr>
                        <td>Location</td>
                        <td>Rostov-on-Don, Russia</td>
                      </tr>
                      <tr>
                        <td>Popular Name/Abbrev.</td>
                        <td>RostSMU, RostGMU</td>
                      </tr>
                      <tr>
                        <td>Institute Type</td>
                        <td>State (Sponsored by Federal Government Budget)</td>
                      </tr>
                      <tr>
                        <td>Year of Establishment</td>
                        <td>1930</td>
                      </tr>
                      <tr>
                        <td>Number of Faculty</td>
                        <td>6 (For International Students)</td>
                      </tr>
                      <tr>
                        <td>Medium of Instruction</td>
                        <td>English Medium-6 Yrs / Russian Medium-7 Yrs</td>
                      </tr>
                      <tr>
                        <td>NEET Required (For Indians only)</td>
                        <td>Yes, It is Required &amp; Mandatory</td>
                      </tr>
                      <tr>
                        <td>Courses Offered</td>
                        <td>General Medicine, Pediatrics, Dentistry, Pharmacy, Preparatory Faculty</td>
                      </tr>
                      <tr>
                        <td>Cost of Living</td>
                        <td>80-120 US Dollars</td>
                      </tr>
                      <tr>
                        <td>Minimum percentage required</td>
                        <td>65% (ECB per subject)</td>
                      </tr>
                      <tr>
                        <td>Official Representative</td>
                        <td>RREC www.rrecrostov.com, www.rrecrussia.com</td>
                      </tr>
                      <tr>
                        <td>Processing Time for MBBS Admission</td>
                        <td>30-32 days Approx.</td>
                      </tr>
                      <tr>
                        <td>Fees for MBBS</td>
                        <td>4250USD (approx) Each Year</td>
                      </tr>
                      <tr>
                        <td>Duration of MBBS</td>
                        <td>6 Years (English Medium)</td>
                      </tr>
                      <tr>
                        <td>University Recognition</td>
                        <td>WHO, NMC of India Approved</td>
                      </tr>
                    </tbody>
                  </table>


                </div>

              </div>
            </div>
          </div>
        </section>

      </main>

    )
  }

}

export default Rostov_state_medical_university;