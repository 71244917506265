import React, { Component } from 'react'

export class Logo extends Component {
    render() {

        return (   
     
            <div className='row'>
             
             <a className="navbar-brand" href={process.env.PUBLIC_URL + '/'}><img src="images/logo.png" className="img-fluid" alt="img" /></a>

            <a className="mr-30" href="tel:1800-572-5827"><img src="images/toll.gif" className="img-fluid" alt="img" /></a>
           
            </div>  
            
     )
    }
}
export default Logo;