import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom";



class Socialmedia extends Component {
  render() {
    return (
      <Fragment>

        <div className="social1">
          <ul>
            <li><a href="tel:917042284508" target="_blank"><img src="../images/phone.png" className="img-fluid" alt="" /></a></li>
            <li><a href="https://wa.me/79515371133" target="_blank"><img src="../images/wht.png" className="img-fluid" alt="" /></a></li>

          </ul>
        </div>


        <div className="app">
          <ul>
            <li><a href={process.env.PUBLIC_URL + '/apply-now'} ><img src="../images/app.png" className="img-fluid" alt="" /></a></li>
          </ul>
        </div>
        <div className="partner">
          <ul>
            <li><a href={process.env.PUBLIC_URL + '/partner-with-us'} ><img src="../images/part.png" className="img-fluid" alt="" /></a></li>
          </ul>
        </div>
      </Fragment>
    )
  }
}

export default Socialmedia
