import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';


export class About_rrec extends Component {
    render() {

        return (
            <main>
                <Helmet>
                    <title>Admission Procedure - Rostov State Medical University, Russia</title>
                    <meta name="description" content="Admission procedure 2024: A prospective student has to apply through our official admission partner:(Reliable Russian Educational Consultants) Call 18005725827" />
                    <meta name="robots" content="index, follow" />
                    <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <link rel="canonical" href="https://rrecrostov.com/admission-procedure-rostsmu/" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Admission Procedure - Rostov State Medical University, Russia" />
                    <meta property="og:description" content="Admission procedure 2024: A prospective student has to apply through our official admission partner:(Reliable Russian Educational Consultants) Call 18005725827" />
                    <meta property="og:url" content="https://rrecrostov.com/" />
                    <meta property="og:site_name" content="Rostov State Medical University, Russia" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>
                <section id="page-banner" class="pt-105 pb-110 bg_cover a1" data-overlay="8">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="page-banner-cont">
                                    <h2>About RREC</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                                            <li class="breadcrumb-item active" aria-current="page">About RREC</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section class="pt-105 pb-110">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="privacy-desc">


                                    <h1>About RREC</h1>

                                    <p>RREC Team has guided a number of students for Medical studies at <a href="https://rrecrostov.com/">Rostov State Medical University</a> since 2007. Many of them completed their degree / degree+post graduations and practicing in India &amp; Abroad. RREC director’s one of the graduates of Rostov State Medical University, completed post-graduation also and helping students in saving valuable time and money with his guidance for study medicine (MBBS) in Rostov State Medical University. We are not agents, but a group of qualified doctors who are trying to facilitate you in achieving your goal.</p>
                                    <h4>Some reasons which make RREC Most trusted educational consultants for Russia.</h4>
                                    <ul>
                                        <li>RREC has the full experience to provide professional consulting services for students who plan to study Medical in RostSMU, Russia.</li>
                                        <li>RREC has close contact with RostSMU for the course information, application requirements, and procedures. Our team is fully ready to handle student applications effectively and efficiently.</li>
                                        <li>RREC sincerely cares for our student’s future and is committed to providing reliable information and keeping to our promise.</li>
                                        <li>RREC Team is well recognized for its reliability. For its professional service and teams of experienced staff in the representative offices, RREC Team is highly regarded within the industry by universities, parents, and students alike.</li>
                                        <li>RREC never hides any facts and truths.</li>
                                        <li>RREC never charges any hidden expenses.</li>
                                        <li>RREC has an excellent student-parent support system.</li>
                                        <li>RREC Assure financial &amp; educational assistance without any hurdle.</li>
                                        <li>RREC Team will assist in the whole process of admission including your visa and travel.</li>
                                    </ul>
                                    <div className='col-sm-12 bg-orange  text-center'>
                                        <h3>Official Contact Details;<br /> For Indian Students: <a href="tel:1800-572-5827">1800-572-5827</a><br /> (Toll-free) | For Other Countries: <a href="tel:+79604430042">+7-960-443-00-42</a></h3>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>

        )
    }

}

export default About_rrec;