import React, { Component } from "react";
import Socialmedia from "./Socialmedia";

export class Footer extends Component {
    render() {
        return (
            <footer id="footer-part">
                <div class="footer-top pt-40 pb-70">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="footer-about mt-40">
                                    <div class="logo">
                                        <a href="#"><img src="images/f-logo.png" alt="Logo" /></a>
                                    </div>
                                    <p>RREC Pvt. Ltd (Reliable Russian Educational Consultants is the authorized official representative of Indians and International students in RostSMU, Russia.</p>
                                    <ul class="mt-20">
                                        <li><a href="https://www.facebook.com/studymedicineinrostovrussia/?ti=as" targe='_blank'><i className="fa fa-facebook-f"></i></a></li>
                                        <li><a href="https://pin.it/3UoAG9A" targe='_blank'><i className="fa fa-pinterest"></i></a></li>
                                        <li><a href="https://twitter.com/rrecrussia_team?t=JpILYKuGm7-gADzaMhxM0Q&s=08" targe='_blank'><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="https://www.instagram.com/rrecrostov_officials/" targe='_blank'><i className="fa fa-instagram"></i></a></li>
                                        <li><a href="https://ru.linkedin.com/in/studymedicinembbsinrussia" targe='_blank'><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6">
                                <div class="footer-link mt-40">
                                    <div class="footer-title pb-25">
                                        <h6>Quick Links</h6>
                                    </div>
                                    <ul>
                                        <li><a href={process.env.PUBLIC_URL + '/'}><i class="fa fa-angle-right"></i>HOME</a></li>
                                        <li><a href={process.env.PUBLIC_URL + '/faculties-fees'}><i class="fa fa-angle-right"></i>FACULTIES &amp; FEES</a></li>
                                        <li><a href={process.env.PUBLIC_URL + '/apply-now'}><i class="fa fa-angle-right"></i>APPLY NOW</a></li>
                                        <li><a href={process.env.PUBLIC_URL + '/official-representative-of-rostsmu'}><i class="fa fa-angle-right"></i>CONTACT US</a></li>
                                    </ul>

                                </div>
                            </div>
                            <div class="col-lg-2 col-md-6 col-sm-6">
                                <div class="footer-link support mt-40">
                                    <div class="footer-title pb-25">
                                        <h6>Quick Links</h6>
                                    </div>
                                    <ul>
                                        <li><a href={process.env.PUBLIC_URL + '/about-rrec'}><i class="fa fa-angle-right"></i>ABOUT RREC </a></li>
                                        <li><a href={process.env.PUBLIC_URL + '/rostsmu-today'}><i class="fa fa-angle-right"></i>ROSTSMU TODAY </a></li>
                                        <li><a href={process.env.PUBLIC_URL + '/hostel-facility'}><i class="fa fa-angle-right"></i>HOSTEL FACILITY </a></li>
                                        <li><a href={process.env.PUBLIC_URL + '/admission'}><i class="fa fa-angle-right"></i>ADMISSION</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="footer-address mt-40">
                                    <div class="footer-title pb-25">
                                        <h6>Contact Us</h6>
                                    </div>
                                    <ul>
                                        <li>
                                            <div class="icon">
                                                <i class="fa fa-home"></i>
                                            </div>
                                            <div class="cont">
                                                <p>#608, 6th floor, Aggarwal Cyber Plaza-1, Netaji Subhash Place, Pitampura, New Delhi -110034 (India)</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <i class="fa fa-phone"></i>
                                            </div>
                                            <div class="cont">
                                                <p>+7-9515371133</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <i class="fa fa-envelope-o"></i>
                                            </div>
                                            <div class="cont">
                                                <p>contact@rrecrussia.com </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="footer-copyright pt-10 pb-25">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="copyright text-md-left text-center pt-15">
                                    <p>Copyright © 2024 Rostov State Medical University, Russia . Presented by RREC Team</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Socialmedia />
            </footer>


        )
    }
}

export default Footer;	