import React, { Component } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import SwiperCore, { FreeMode, Pagination, Autoplay } from "swiper";



// import required modules
import { Navigation } from "swiper";

export class Sliders extends Component {

    
    render() {
        SwiperCore.use([Autoplay]);
        return (
            <>
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper"  autoplay={{ delay: 4000 }}>
      <SwiperSlide>
 <div className="single-slider bg_cover pt-150 bg-1"  data-overlay="4">
            <div className="container">
                <div className="row">
                <div className="col-xl-8 col-lg-10">
                        <div className="slider-cont">
                            <h1 data-animation="fadeInLeft" data-delay="1s">Welcome to Study Medicine <br />in Rostov State Medical University</h1>
                            <p data-animation="fadeInUp" data-delay="1.3s">Our university is one of the leading Russian Medical Universities, providing a broad set of educational programs.</p>
                            <ul>
                                <li><a data-animation="fadeInUp" data-delay="1.6s" className="main-btn" href={process.env.PUBLIC_URL + '/rostov-state-medical-university'}>Read More</a></li>
                                <li><a data-animation="fadeInUp" data-delay="1.9s" className="main-btn" href={process.env.PUBLIC_URL + '/official-representative-of-rostsmu'}>Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</SwiperSlide>
<SwiperSlide>
 <div className="single-slider bg_cover pt-150 bg-3"  data-overlay="4">
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-lg-10">
                        <div className="slider-cont">
                            <h1 data-animation="fadeInLeft" data-delay="1s">Experience the multicultural<br /> life of Rostov-on-Don</h1>
                            <p data-animation="fadeInUp" data-delay="1.3s">Be a part of Rostov State Medical University, Rank 1 university of Southern Russia. </p>
                            <ul>
                                <li><a data-animation="fadeInUp" data-delay="1.6s" className="main-btn" href={process.env.PUBLIC_URL + '/about-rrec'}>Read More</a></li>
                                <li><a data-animation="fadeInUp" data-delay="1.9s" className="main-btn" href={process.env.PUBLIC_URL + '/official-representative-of-rostsmu'}>Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</SwiperSlide>

<SwiperSlide>
 <div className="single-slider bg_cover pt-150 bg-2"  data-overlay="4">
            <div className="container">
                <div className="row">
                <div className="col-xl-8 col-lg-10">
                        <div className="slider-cont">
                            <h1 data-animation="fadeInLeft" data-delay="1s">Sevnth oldest government<br /> medical university of Russia.</h1>
                            <p data-animation="fadeInUp" data-delay="1.3s">Full English Medium course - 6 years General Medicine.</p>
                            <ul>
                                <li><a data-animation="fadeInUp" data-delay="1.6s" className="main-btn" href={process.env.PUBLIC_URL + '/about-rrec'}>Read More</a></li>
                                <li><a data-animation="fadeInUp" data-delay="1.9s" className="main-btn" href={process.env.PUBLIC_URL + '/official-representative-of-rostsmu'}>Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</SwiperSlide>
      </Swiper>
    </>

            )
        }
    }
export default Sliders;