import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import "swiper/css/bundle";

import { Footer } from './components/layout/Footer';
import { Header } from './components/layout/Header';

import Home from './components/Home';
import Admission_procedure_rostsmu from './components/pages/Admission_procedure_rostsmu';
import Apply_now from './components/pages/Apply_now';
import Admission_procedure from './components/pages/Admission_procedure';
import About_rrec from './components/pages/About_rrec';
import Admission from './components/pages/Admission';
import Faculties_fees from './components/pages/Faculties_fees';
import Hostel_facility from './components/pages/Hostel_facility';
import Official_representative_of_rostsmu from './components/pages/Official_representative_of_rostsmu';
import Reviews from './components/pages/Reviews';
import Rostov_state_medical_university from './components/pages/Rostov_state_medical_university';
import Rostsmu_today from './components/pages/Admission';
import Partner_with_us from './components/pages/Partner_with_us';
import Thank_you from "./components/pages/Thank_you";
import Our_offices from './components/pages/Our_offices';

const appHistory = {
  basename: process.env.PUBLIC_URL
};
const history = createBrowserHistory(appHistory);
function App() {

  return (
    <div className="page">
      <Header />

      <Router basename={process.env.PUBLIC_URL} history={history}>
        <Route exact path='/' component={Home} />



        <Route exact path="/admission-procedure" component={Admission_procedure} />
        <Route exact path="/apply-now" component={Apply_now} />
        <Route exact path="/faculties-fees" component={Faculties_fees} />
        <Route exact path="/admission-procedure-rostsmu" component={Admission_procedure_rostsmu} />
        <Route exact path="/official-representative-of-rostsmu" component={Official_representative_of_rostsmu} />
        <Route exact path="/about-rrec" component={About_rrec} />
        <Route exact path="/rostov-state-medical-university" component={Rostov_state_medical_university} />
        <Route exact path="/rostsmu-today" component={Rostsmu_today} />
        <Route exact path="/hostel-facility" component={Hostel_facility} />
        <Route exact path="/admission" component={Admission} />
        <Route exact path="/reviews" component={Reviews} />
        <Route exact path="/partner-with-us" component={Partner_with_us} />
        <Route exact path="/thank-you" component={Thank_you} />
        <Route exact path="/our-offices" component={Our_offices} />



        <Footer />
      </Router>


    </div>
  );
}

export default App;
