import React, { Component } from 'react';
import Topheader from './Topheader';
import News from './News';
import { Menu } from './Menu';
import Logo from './Logo';
import Call from './Call';

export class Header extends Component {
    componentDidMount() {
        window.scroll(0, 0)
    }

    render() {
        return (
            <header id="header-part">
                <div className='news'>
                    <div className='container'>
                        <News />
                    </div>
                </div>
                <div className="header-top  d-lg-block">
                    <div className="container">
                        <Topheader />
                    </div>
                </div>

                <div className="navigation">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Menu />
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        )
    }
}

export default Header;