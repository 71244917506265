import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';


export class Faculties_fees extends Component {
    render() {

        return (
            <main>
                <Helmet>
                    <title>Fees 2024-25, faculties, hostel fee, medical insurance fee in Rostov State Medical University</title>
                    <meta name="description" content="Fees 2024-25 of Rostov State Medical University for MBBS and faculties, hostel fee, medical insurance fee, Webinars of 2020" />
                    <meta name="robots" content="index, follow" />
                    <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <link rel="canonical" href="https://rrecrostov.com/faculties-fees" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Fees 2024-25, faculties, hostel fee, medical insurance fee in Rostov State Medical University" />
                    <meta property="og:description" content="Fees 2024-25 of Rostov State Medical University for MBBS and faculties, hostel fee, medical insurance fee, Webinars of 2020" />
                    <meta property="og:url" content="https://rrecrostov.com/" />
                    <meta property="og:site_name" content="Rostov State Medical University, Russia" />
                    <meta property="article:modified_time" content="" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>
                <section id="page-banner" class="pt-105 pb-110 bg_cover a1" data-overlay="8">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="page-banner-cont">
                                    <h2>Faculties fees</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                                            <li class="breadcrumb-item active" aria-current="page">Faculties fees</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <section class="pt-105 pb-110">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="privacy-desc1">

                                    <div className='row pt-50'>
                                        <div className='col-sm-6'>

                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/xgFxCdw3opU" title="Rostov State Medical University | 130 Yrs Of Excellence | Choose The Best For You | MBBS In Russia" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>



                                        <div className='col-sm-6'>
                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/EqdUuJZG2Fo" title="Rostov State Medical University | Russia | Official" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>

                                    </div>                 <h1>Rostov state medical University</h1>
                                    <p>Rostov state medical University is one of the largest scientific and educational centers in the field of health and medical Sciences in the South of Russia. It trains highly qualified specialists in educational programs of secondary professional education, higher education programs-specialist programs, bachelor's programs, graduate, and residency training programs.</p>




                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="tab-content" id="myTabContent">
                                                <div class="tab-pane fade active show" id="faq-accordion" role="tabpanel" aria-labelledby="faq-accordion-tab">
                                                    <div class="faq-accordion-cont">

                                                        <div class="accordion" id="accordionExample">
                                                            <div class="card">
                                                                <div class="card-header" id="headingOne">
                                                                    <a href="#" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                        <ul>
                                                                            <li><i class="fa fa-question-circle" aria-hidden="true"></i></li>
                                                                            <li><span class="head">Why ROSTSMU is Best Medical University Abroad?</span></li>
                                                                            <li></li>
                                                                        </ul>
                                                                    </a>
                                                                </div>

                                                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                                    <div class="card-body">
                                                                        <ul>
                                                                            <li>Listed in Top 20 Medical Universities.</li>
                                                                            <li>Top Rank by Ministry of Health, Russia.</li>
                                                                            <li>Best Results in FMGE/NMC Exam.</li>
                                                                            <li>1200 + Indian MBBS Graduates working in India &amp; Abroad  </li>
                                                                            <li>Affordable Fee.</li>
                                                                            <li>No Donation, No Capitation Fee</li>
                                                                            <li>No IELTS, No TOEFL</li>
                                                                            <li>Parents will receive weekly updates from the RREC team for attendance &amp; student progress. </li>
                                                                            <li>Hostels are on Campus.</li>
                                                                            <li>Indian Food is available.</li>
                                                                            <li>24*7 Hrs students safety.</li>
                                                                        </ul>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="card">
                                                                <div class="card-header" id="headingTow">
                                                                    <a href="#" data-toggle="collapse" class="collapsed" data-target="#collapseTow" aria-expanded="true" aria-controls="collapseTow">
                                                                        <ul>
                                                                            <li><i class="fa fa-question-circle" aria-hidden="true"></i></li>
                                                                            <li><span class="head">Rostov State Medical University: Faculties </span></li>
                                                                            <li></li>
                                                                        </ul>
                                                                    </a>
                                                                </div>

                                                                <div id="collapseTow" class="collapse" aria-labelledby="headingTow" data-parent="#accordionExample">
                                                                    <div class="card-body">
                                                                        <h3>Nowadays there are six faculties that provide training to foreign students. </h3>
                                                                        <h4>Faculties:</h4>
                                                                        <ul>
                                                                            <li>General Medicine (MBBS)</li>
                                                                            <li>Dentistry (BDS)</li>
                                                                            <li>Pediatrics</li>
                                                                            <li>Pharmacy</li>
                                                                            <li>Faculty of postgraduate &amp; continuous education</li>
                                                                            <li>Preparatory faculty (Russian Language Course)</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="card">
                                                                <div class="card-header" id="headingThree">
                                                                    <a href="#" data-toggle="collapse" class="collapsed" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                        <ul>
                                                                            <li><i class="fa fa-question-circle" aria-hidden="true"></i></li>
                                                                            <li><span class="head">Fees Structure for 2024-25</span></li>
                                                                            <li></li>
                                                                        </ul>
                                                                    </a>
                                                                </div>
                                                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                                                    <div class="card-body">
                                                                        <h4>(Admissions are open for 2024 for all faculties)</h4>

                                                                        <table className="table" cellpadding="8" cellspacing="8">
                                                                            <tbody>
                                                                                <tr><th>Faculties</th>
                                                                                    <th>Tuition Fees</th>
                                                                                    <th>Duration of Course</th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>General Medicine (English Medium)</td>
                                                                                    <td> 326 000 rubles</td>
                                                                                    <td> 6 years</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>General Medicine (Russian Medium)</td>
                                                                                    <td> 224 700 rubles</td>
                                                                                    <td> 6 years</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Dentistry</td>
                                                                                    <td> 257 100 rubles</td>
                                                                                    <td>5 years</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Pharmacy</td>
                                                                                    <td>224 700 rubles</td>
                                                                                    <td> 5 years</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Pediatrics</td>
                                                                                    <td>224 700 rubles</td>
                                                                                    <td> 6 years</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Preparatory Faculty (Russian Language Course)</td>
                                                                                    <td> 194 000 rubles</td>
                                                                                    <td> 8-10 months</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Post-Graudation Courses (MD,MDS)</td>
                                                                                    <td >4500-6000 USD Dollars per year (Depends on selected department)</td>
                                                                                    <td> 2 years</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="card">
                                                                <div class="card-header" id="headingFive">
                                                                    <a href="#" data-toggle="collapse" class="collapsed" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                                        <ul>
                                                                            <li><i class="fa fa-question-circle" aria-hidden="true"></i></li>
                                                                            <li><span class="head">Hostel Fee</span></li>
                                                                            <li></li>
                                                                        </ul>
                                                                    </a>
                                                                </div>
                                                                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                                                    <div class="card-body">
                                                                        <ul>
                                                                            <li> Hostel Fee – 21720 Rubles (near about 300 USD Dollars per year)</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="card">
                                                                <div class="card-header" id="headingFore">
                                                                    <a href="#" data-toggle="collapse" class="collapsed" data-target="#collapseFore" aria-expanded="false" aria-controls="collapseFore">
                                                                        <ul>
                                                                            <li><i class="fa fa-question-circle" aria-hidden="true"></i></li>
                                                                            <li><span class="head"> Compulsory Medical Insurance</span></li>
                                                                            <li></li>
                                                                        </ul>
                                                                    </a>
                                                                </div>
                                                                <div id="collapseFore" class="collapse" aria-labelledby="headingFore" data-parent="#accordionExample">
                                                                    <div class="card-body">
                                                                        <ul>
                                                                            <li> Compulsory Medical Insurance- (6500-8000 Rubles) 100-120 USD Dollars per year.</li>
                                                                            <li> As per the laws of the Russian Federation, all students have to take a compulsory medical insurance policy.</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="card">
                                                                <div class="card-header" id="headingSix">
                                                                    <a href="#" data-toggle="collapse" class="collapsed" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                                        <ul>
                                                                            <li><i class="fa fa-question-circle" aria-hidden="true"></i></li>
                                                                            <li><span class="head">Registration &amp; Visa Extension</span></li>
                                                                            <li></li>
                                                                        </ul>
                                                                    </a>
                                                                </div>
                                                                <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                                                                    <div class="card-body">
                                                                        <ul>
                                                                            <li> 1600 Rubles (near about 20-30 USD Dollars per year)</li>
                                                                        </ul>

                                                                        <p>The Rostov State Medical fee structure: Some Agents even may ask for the whole 6-year amount or fees don't do such mistakes. You should not do this. The reason being, RostSMU asks you to pay fees yearly only. You should always get written assurance for whatever the promises they offer you regarding the fees. Whether you are a student or parent, you need to be smart enough to tackle such issues and avoid getting trapped in any of the baffling assurance, entertaining <a href="https://rrecrostov.com/faculties-fees/"><strong>Rostov State Medical University fee structure</strong></a> or floating schemes used by such agents. Most of the information regarding RostSMU or RostGMU is available at the <strong>Rostov State Medical University website </strong>or you can have it <strong>directly from the official Representative RREC</strong>.</p>
                                                                        <p>RostSMU is a highly sought-after university in Russia for doing MBBS. If you are looking for a medical university that can offer world-class education at an affordable budget you can always pick Rostov State Medical University in Russia. The <strong>RREC team</strong>, the official representative of RostSMU will always be there to provide all assistance and consultation for admission in RostSMU. Do not hesitate to contact us at <strong><a href="https://rrecrostov.com/">www.rrecrostov.com</a> </strong>for a free consultation.<strong> Admission Cell Contact: <a href="tel:+7-960-443-00-42">+7-960-443-00-42</a></strong></p>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>





                                </div>
                            </div>
                        </div>
                    </div>

                </section>

            </main>

        )
    }

}

export default Faculties_fees;