import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';


export class Rostsmu_today extends Component {
    render() {

        return (
            <main>
                <Helmet>
                    <title>Admission</title>
                    <meta name="description" content="Admission procedure 2024: A prospective student has to apply through our official admission partner:(Reliable Russian Educational Consultants) Call 18005725827" />
                    <meta name="robots" content="index, follow" />
                    <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <link rel="canonical" href="https://rrecrostov.com/hostel-facility/" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Admission Procedure - Rostov State Medical University, Russia" />
                    <meta property="og:description" content="Admission procedure 2024: A prospective student has to apply through our official admission partner:(Reliable Russian Educational Consultants) Call 18005725827" />
                    <meta property="og:url" content="https://rrecrostov.com/" />
                    <meta property="og:site_name" content="Rostov State Medical University, Russia" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>
                <section id="page-banner" class="pt-105 pb-110 bg_cover a1" data-overlay="8">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="page-banner-cont">
                                    <h2>Hostel Facility</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                                            <li class="breadcrumb-item active" aria-current="page">Hostel Facility</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <section class="pt-105 pb-110">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="privacy-desc">
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/_yoy_HK5NeA" title="Rostov State Medical University | Medical Students Hostels | Official View" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <h2>Accommodation and hostel facility</h2>
                                    <p>Accommodation or hostel facility is available in Rostov state medical university. The accommodation is based on a sharing basis where students are supposed to accommodate 3 students in a large room. Students are provided a separate bed, wardrobe, and study table respectively. Students can watch this video for Hostel view:</p>





                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>

        )
    }

}

export default Rostsmu_today;