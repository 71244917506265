import React, { Component } from 'react';
import { Header } from '../layout/Header';
import { Helmet } from "react-helmet";
export class Thank_you extends Component {
    render() {

        return (
            <main>

                <section id="page-banner" className="pt-105 pb-110 bg_cover a1" data-overlay="8">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="page-banner-cont">
                                    <h2>Thank You</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Thank you</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pt-105 pb-110">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="privacy-desc">
                                    <h2 className='text-center'>Thank You  for your message. it has been sent</h2>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>


 )
}

}

export default Thank_you;