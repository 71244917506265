import React, { Component } from 'react';
import { Helmet } from "react-helmet";



class Our_offices extends Component {
    render() {


        return (
            <main>
                <Helmet>

                    <title data-react-helmet="true">Our Offices in India | RREC</title>
                    <meta name="description" content="Admission is open for study in Russia, study MBBS in Russia, study medicine in Russia" />
                    <link data-react-helmet="true" rel="canonical" href="https://rrecrussia.com/our-offices/" />


                </Helmet>
                <section id="page-banner" class="pt-105 pb-110 bg_cover a1" data-overlay="8">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="page-banner-cont">
                                    <h2>Our Branch Offices</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                                            <li class="breadcrumb-item active" aria-current="page">Our Branch Offices</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <div className="contact__area site-main">
                    <div className="container">
                        <div className="contact-group-area pb-70 pt-145">
                            <div className="row">

                                <div className="col-xxl-6 col-xl-6 col-lg-6">

                                    <div className="contact__gpinfo grey-soft2-bg mb-50">

                                        <div className="contact__gpinfo-content">
                                            <table className="table" cellpadding="8" cellspacing="8">
                                                <tr><th>HEAD OFFICE: The RREC Team-MBBS in Russia </th></tr>

                                                <tr>
                                                    <td><span>Address</span><br />Aggarwal Cyber Plaza-1, Unit 608, Sixth Floor, NSP-110034</td>
                                                </tr>

                                                <tr>
                                                    <td><span>Student-Helpline</span><br /><a href="tel:1800-572-5827">1800-572-5827</a></td>
                                                </tr>

                                                <tr>
                                                    <td><span>Indian office Numbers</span><br /><a href="tel:+91-7042284508">+91-7042284508</a><br />  <a href="tel:+91 7042284509">+91 7042284509</a></td>
                                                </tr>


                                                <tr>
                                                    <td><span>Email</span><br /><a href="mailto:contact@rrecrussia.com">contact@rrecrussia.com</a><br />  <a href="mailto:rrec.educonsultants@gmail.com">rrec.educonsultants@gmail.com</a></td>
                                                </tr>

                                            </table>
                                        </div>
                                    </div>


                                </div>

                                <div className="col-xxl-6 col-xl-6 col-lg-6">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13999.432141428317!2d77.149523!3d28.693893000000003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf2fcd5bfa7590c08!2sThe%20RREC%20Team-MBBS%20in%20Russia%20%7C%20Top%20Medical%20Universities%20of%20Russia!5e0!3m2!1sen!2sin!4v1655533356841!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>

                                <div className='bg-light pd-50'>
                                    <div className='row'>

                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <div className="contact__gpinfo grey-soft2-bg mb-50">
                                                <div className="contact__gpinfo-icon text-center">
                                                    <i className="flaticon-pin"></i>
                                                </div>
                                                <div className="contact__gpinfo-content">
                                                    <table className="table" cellpadding="8" cellspacing="8">
                                                        <tr><th>Bihar </th></tr>

                                                        <tr>
                                                            <td><span>Address</span><br />Office No. L - 219, 2nd floor, Dumraon Place<br /> P.S.- Kotwali, Fraser Road, Patna-800001</td>
                                                        </tr>

                                                        <tr>
                                                            <td><span> Contact Numbers</span><br /><a href="tel:+91-7042284508">+91-7042284508</a><br /><a href="tel:7280015827">7280015827</a></td>
                                                        </tr>

                                                        <tr>
                                                            <td><span>Email</span><br /><a href="mailto:contact@rrecrussia.com">contact@rrecrussia.com</a></td>
                                                        </tr>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <div className="contact__gpinfo grey-soft2-bg mb-50">

                                                <div className="contact__gpinfo-content">
                                                    <table className="table" cellpadding="8" cellspacing="8">
                                                        <tr><th>Uttar Pradesh</th></tr>

                                                        <tr>
                                                            <td><span>Address</span><br />1st Floor, T.S. Tower Ashoka Marg<br /> Near Shakti Bhawan - Hazratganj
                                                                Lucknow- 226001,  U.P</td>
                                                        </tr>

                                                        <tr>
                                                            <td><span> Contact Number</span><br /><a href="tel:+91-9792410586">+91-9792410586</a>, <a href="tel:+91-7042284508">+91-7042284508</a></td>
                                                        </tr>

                                                        <tr>
                                                            <td><span>Email</span><br /><a href="mailto:contact@rrecrussia.com">contact@rrecrussia.com</a></td>
                                                        </tr>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <div className="contact__gpinfo grey-soft2-bg mb-50">

                                                <div className="contact__gpinfo-content">
                                                    <table className="table" cellpadding="8" cellspacing="8">
                                                        <tr><th>Gujarat </th></tr>

                                                        <tr>
                                                            <td><span>Address</span><br />S-16, Third Floor, Atlanta Shoppers<br /> Near Reliance Mall, Vesu - Surat, Pincode - 395007 </td>
                                                        </tr>

                                                        <tr>
                                                            <td><span> Contact Number</span><br /><a href="tel:+91-7042284508">+91-7042284508</a>, <a href="tel:+91-9601415827">+91-9601415827</a></td>
                                                        </tr>


                                                        <tr>
                                                            <td><span>Email</span><br /><a href="mailto:contact@rrecrussia.com">contact@rrecrussia.com</a></td>
                                                        </tr>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <div className="contact__gpinfo grey-soft2-bg mb-50">

                                                <div className="contact__gpinfo-content">
                                                    <table className="table" cellpadding="8" cellspacing="8">
                                                        <tr><th>Chhattisgarh </th></tr>

                                                        <tr>
                                                            <td><span>Address</span><br />280, Sector-10, Zonal Market, Bhilai-490006</td>
                                                        </tr>

                                                        <tr>
                                                            <td><span> Contact Numbers</span><br /><a href="tel:+91-7880283788">+91-7880283788</a><br /> <a href="tel:+91-7042284508">+91-7042284508</a><br /> <a href="tel:0788-4040842">0788-4040842</a></td>
                                                        </tr>

                                                        <tr>
                                                            <td><span>Email</span><br /><a href="mailto:contact@rrecrussia.com">contact@rrecrussia.com</a></td>
                                                        </tr>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>





                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <div className="contact__gpinfo grey-soft2-bg mb-50">

                                                <div className="contact__gpinfo-content">
                                                    <table className="table" cellpadding="8" cellspacing="8">
                                                        <tr><th>Mumbai (M.H) </th></tr>

                                                        <tr>
                                                            <td><span>Address</span><br />Tolaram Smruti CHS, Office-16<br />
                                                                Near Fertilizer Township Monorail Station<br />
                                                                Chembur East, Mumbai-400074</td>
                                                        </tr>

                                                        <tr>
                                                            <td><span> Contact Numbers</span><br /><a href="tel:+91-7045334570 ">+91-7045334570</a><br /> <a href="tel:+918082481434">+91 8082481434</a></td>
                                                        </tr>

                                                        <tr>
                                                            <td><span>Email</span><br /><a href="mailto:contact@rrecrussia.com">contact@rrecrussia.com</a></td>
                                                        </tr>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <div className="contact__gpinfo grey-soft2-bg mb-50">

                                                <div className="contact__gpinfo-content">
                                                    <table className="table" cellpadding="8" cellspacing="8">
                                                        <tr><th> Pune (M.H)  </th></tr>

                                                        <tr>
                                                            <td><span>Address</span><br />Trupti Business Centre: 32 A, Darekar Heights<br />
                                                                1st floor, Office no. 8, Karve Road, Pune – 411004</td>
                                                        </tr>

                                                        <tr>
                                                            <td><span> Contact Number</span><br /><a href="tel:+91-7045334570">+91-7045334570</a><br /> <a href="tel:+918082481434">+91 8082481434</a></td>
                                                        </tr>

                                                        <tr>
                                                            <td><span>Email</span><br /><a href="mailto:contact@rrecrussia.com">contact@rrecrussia.com</a></td>
                                                        </tr>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <div className="contact__gpinfo grey-soft2-bg mb-50">

                                                <div className="contact__gpinfo-content">
                                                    <table className="table" cellpadding="8" cellspacing="8">
                                                        <tr><th> Our South India Representative </th></tr>


                                                        <tr>
                                                            <td><span> Contact Number</span><br /> <a href="tel:+91- 9474277787">+91- 9474277787</a>, <a href="tel:+7-9001282729">+7-9001282729</a></td>
                                                        </tr>


                                                    </table>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row">
                                            <div class="col-lg-12 col-xs-12">

                                                <h5>Special message to students or agents; you can contact us for<br /> Direct Admissions, where we don't have representatives at +79515277045, +91-7042284508.</h5>

                                                <h6>Worldwide presence: India, Russia, Thailand, Bangladesh, Nepal, Nigeria, Ghana, Swaziland, Tanzania, South Africa, Latin America, Malaysia, Botswana, UAE, Zambia, Cameroon, Saudi Arab, Kenya, Lebanon, Namibia, Somalia, Oman, Kuwait, Mauritius, Zimbabwe, Jordan, Morocco.</h6>

                                                <h6>RREC Pvt. Ltd is the authorized Official representative for Asian, African, Arabian and European countries, a student can apply with us for Direct Admissions for Study in Russia for all universities and courses, which are mentioned on our website.</h6>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </main>




        )
    }

}

export default Our_offices;