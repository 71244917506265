import React, { Component } from 'react';
import { Upcoming } from './layout/Upcoming';
import { Facebook } from './layout/Facebook';
import { Sliders } from './sliders/Sliders';
import { Services } from './sliders/Services';
import { Youtube } from './sliders/Youtube';
import { Clients } from './sliders/Clients';
import Faq from './layout/Faq';
import { Helmet } from "react-helmet";




export class Home extends Component {

    render() {
        return (
            <main>
                <Helmet>
                    <title>Rostov State Medical University - Admission process - eligibility criteria - fee structure - documents required for MBBS - Quick fact check - RostSMU Today - know about university - accreditation and recognition - Accommodation and hostel - duration of MBBS - reviews</title>
                    <meta name="description" content="Rostov State Medical University - Admission process - eligibility criteria - fee structure - documents required for MBBS - Quick fact check - RostSMU Today - know about university - accreditation and recognition - Accommodation and hostel - duration of MBBS - reviews" />
                    <meta name="robots" content="index, follow" />
                    <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <link rel="canonical" href="https://rrecrostov.com/" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Rostov State Medical University - Admission process - eligibility criteria - fee structure - documents required for MBBS - Quick fact check - RostSMU Today - know about university - accreditation and recognition - Accommodation and hostel - duration of MBBS - reviews" />
                    <meta property="og:description" content="Rostov State Medical University - Admission process - eligibility criteria - fee structure - documents required for MBBS - Quick fact check - RostSMU Today - know about university - accreditation and recognition - Accommodation and hostel - duration of MBBS - reviews" />
                    <meta property="og:url" content="https://rrecrostov.com/" />
                    <meta property="og:site_name" content="Rostov State Medical University, Russia" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>
                <section id="slider-part" className="slider-active ">
                    <Sliders />
                </section>
                <section id="category-part">
                    <div className="container">
                        <div className="category pt-40 pb-80">
                            <div className="row">

                                <div className="col-lg-10 offset-lg-1 col-md-8 offset-md-2 col-sm-8 offset-sm-2 col-8 offset-2">
                                    <div className="row category-slide mt-40">
                                        <div className="col-lg-4">
                                            <a href={process.env.PUBLIC_URL + '/rostov-state-medical-university'}>
                                                <span className="single-category text-center color-1">
                                                    <span className="icon">
                                                        <img src="images/s1.png" alt="Icon" />
                                                    </span>
                                                    <span className="cont">
                                                        <span>University</span>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div className="col-lg-4">
                                            <a href={process.env.PUBLIC_URL + '/faculties-fees'}>
                                                <span className="single-category text-center color-2">
                                                    <span className="icon">
                                                        <img src="images/s2.png" alt="Icon" />
                                                    </span>
                                                    <span className="cont">
                                                        <span>Faculties &amp; Fees</span>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div className="col-lg-4">
                                            <a href={process.env.PUBLIC_URL + '/partner-with-us'}>
                                                <span className="single-category text-center color-3">
                                                    <span className="icon">
                                                        <img src="images/s3.png" alt="Icon" />
                                                    </span>
                                                    <span className="cont">
                                                        <span>Become a Partner</span>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div className="col-lg-4">
                                            <a href={process.env.PUBLIC_URL + '/admission-procedure'}>
                                                <span className="single-category text-center color-1">
                                                    <span className="icon">
                                                        <img src="images/s4.png" alt="Icon" />
                                                    </span>
                                                    <span className="cont">
                                                        <span>Admission Procedure</span>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="about-part" className="pt-65">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="section-title mt-50">
                                    <h5>About us</h5>
                                    <img src="images/about2.jpg" alt="About" />
                                    <h2>Official Admission Partner Of Rostov State Medical University for Asia, Africa & Arab Countries</h2>
                                </div>
                                <div className="about-cont">
                                    <p>RREC Pvt. Ltd (Reliable Russian Educational Consultants) is the authorized official representative of Indians and International students of Rostov state medical university, Russia. We are providing our services in Rostov state medical university since 2007 for International students admissions. We have close contact with university for the course information, application requirements and procedures. Our team are fully ready to handle student application effectively and efficiently. Rrec team sincerely care our student's future and are committed to provide reliable information and keeping to our promise.</p>

                                </div>
                            </div>
                            <div className="col-lg-6 offset-lg-1">
                                <div className="about-event mt-50">

                                    <ul>
                                        <li>
                                            <div className="single-event">
                                                <a href="#"><h4>MBBS (GENERAL MEDICINE)</h4></a>
                                                <span className='text-red'>  326 000 Rubles </span>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="single-event">
                                                <a href="#"><h4>DENTISTRY (BDS)</h4></a>
                                                <span className='text-red'> 257 100 Rubels  </span>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="single-event">
                                                <a href="#"><h4>PHARMACY</h4></a>
                                                <span className='text-red'> 224 700 Rubles </span>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="single-event">
                                                <a href="#"><h4>PREPARATORY FACULTY</h4></a>
                                                <span className='text-red'> 194 000 Rubels </span>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="single-event">
                                                <a href="#"><h4>POST-GRADUATION</h4></a>
                                                <span className='text-red'> 4500-6000 USD Dollars per year </span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="single-event">
                                                <a href="#"><h4>PEDIATRICS</h4></a>
                                                <span className='text-red'>224 700 Rubles </span>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="about-bg">
                        <img src="images/bg-1.png" alt="About" />
                    </div>
                </section>


                <section id="apply-aprt" class="pb-120">
                    <div class="container">
                        <div class="apply">
                            <div class="row no-gutters">
                                <div class="col-lg-6">
                                    <div class="apply-cont apply-color-1">
                                        <h3>Apply Now For 2024-25</h3>
                                        <p>Official Admission Partner Of Rostov State Medical University</p>
                                        <a href={process.env.PUBLIC_URL + '/apply-now'} className="main-btn">Apply Now</a>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="apply-cont apply-color-2">
                                        <h3>Become a Partner</h3>
                                        <p>Want to Become a Partner Associate? Submit your Query</p>
                                        <a href={process.env.PUBLIC_URL + '/partner-with-us'} className="main-btn">Apply Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="course-part" class="pt-115 pb-120 gray-bg">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="section-title pb-45">
                                    <h5>Turning MBBS Dream Abroad Into Reality</h5>
                                    <h2>Rostov State Medical University</h2>
                                </div>
                            </div>
                        </div>
                        <Services />
                    </div>
                </section>

                <section id="testimonial" className="bg_cover pt-115 pb-115 bg-t" data-overlay="8">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title pb-40">
                                    <h5>Testimonial</h5>
                                    <h2> What our students, <br />graduates says about RostSMU</h2>
                                </div>
                            </div>
                        </div>
                        <Clients />
                    </div>
                </section>


                <section id="course-part" class="pt-115 pb-120 gray-bg">
                    <div class="container">
                        <Youtube />
                    </div>
                </section>


            </main>
        )
    }

}

export default Home;
