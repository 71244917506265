import React, { Component } from 'react';


export class Topheader extends Component {
    render() {

        return (

            <div className="row">
                <div className="col-md-7">
                    <div className="header-contact ">
                        <ul>
                            <li><i className="fa fa-envelope"></i><a href="mailto:contact@rrecrussia.com">contact@rrecrussia.com</a></li>
                            <li><i className="fa fa-phone"></i><span><a href="tel:+91-7042284508">+91-7042284508</a></span></li>
                            <li><span className="blink-text">Admission Helpline:</span>  <a href="tel:+91-7669533991" className="blink-text">+91-7669533991</a></li>
                   
                        </ul>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="header-right d-flex justify-content-end text-right">
                        <div className="social d-flex">
                            <span className="follow-us"> <a className="fright" href="#"><span><div id="google_translate_element">  </div> </span></a> </span>
                         
                        </div>

                    </div>
                </div>
                <div className="col-md-3">
                    <div className="header-right d-flex justify-content-end">
                        <div className="social d-flex">
                            <span className="follow-us">Follow Us :</span>
                            <ul>
                                <li><a href="https://www.facebook.com/studymedicineinrostovrussia/?ti=as" targe='_blank'><i className="fa fa-facebook-f"></i></a></li>
                                <li><a href="https://pin.it/3UoAG9A" targe='_blank'><i className="fa fa-pinterest"></i></a></li>
                                <li><a href="https://twitter.com/rrecrussia_team?t=JpILYKuGm7-gADzaMhxM0Q&s=08" targe='_blank'><i className="fa fa-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/rrecrostov_officials/" targe='_blank'><i className="fa fa-instagram"></i></a></li>
                                <li><a href="https://ru.linkedin.com/in/studymedicinembbsinrussia" targe='_blank'><i className="fa fa-linkedin"></i></a></li>
                            </ul>
                        </div>

                    </div>
                </div>


            </div>

        )
    }
}
export default Topheader;