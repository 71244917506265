import React, { Component }  from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import SwiperCore, { FreeMode, Pagination, Autoplay } from "swiper";

export class Youtube extends Component {

    
    render() {
       
        return (
            <div class="row course-slide mt-30">

<div class="col-lg-4"><div class="single-patnar text-center mt-40"><iframe width="100%" height="315" src="https://www.youtube.com/embed/EqdUuJZG2Fo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>

<div class="col-lg-4"><div class="single-patnar text-center mt-40"><iframe width="100%" height="315" src="https://www.youtube.com/embed/aWRUegjs4N8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>

<div class="col-lg-4"><div class="single-patnar text-center mt-40"><iframe width="100%" height="315" src="https://www.youtube.com/embed/EqdUuJZG2Fo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>

<div class="col-lg-4"><div class="single-patnar text-center mt-40"><iframe width="100%" height="315" src="https://www.youtube.com/embed/WNn4v0qb574" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>

<div class="col-lg-4"><div class="single-patnar text-center mt-40"><iframe width="100%" height="315" src="https://www.youtube.com/embed/_yoy_HK5NeA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>

<div class="col-lg-4"><div class="single-patnar text-center mt-40"><iframe width="100%" height="315" src="https://www.youtube.com/embed/jn7L47BPrjI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>

<div class="col-lg-4"><div class="single-patnar text-center mt-40"><iframe width="100%" height="315" src="https://www.youtube.com/embed/N96O9LridA0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>

<div class="col-lg-4"><div class="single-patnar text-center mt-40"><iframe width="100%" height="315" src="https://www.youtube.com/embed/cn1LvbvhNvU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>

<div class="col-lg-4"><div class="single-patnar text-center mt-40"><iframe width="100%" height="315" src="https://www.youtube.com/embed/Te5FvEdj0io" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>

<div class="col-lg-4"><div class="single-patnar text-center mt-40"><iframe width="100%" height="315" src="https://www.youtube.com/embed/d1C_DFa5v7M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>

<div class="col-lg-4"><div class="single-patnar text-center mt-40"><iframe width="100%" height="315" src="https://www.youtube.com/embed/xgFxCdw3opU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>

<div class="col-lg-4"><div class="single-patnar text-center mt-40"><iframe width="100%" height="315" src="https://www.youtube.com/embed/EqdUuJZG2Fo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>

                </div>            
     
            )
        }
    }
export default Youtube;