import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';


export class Official_representative_of_rostsmu extends Component {
    render() {

        return (
            <main>
                <Helmet>
                    <title>Contact Us - Official Representative Foreign Students</title>
                    <meta name="description" content="A prospective student has to apply through our official admission partner: RREC (Reliable Russian Educational Consultants), Call 1800-572-5827 (Indian Students), +79604430042 (For International Students)" />
                    <meta name="robots" content="index, follow" />
                    <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <link rel="canonical" href="https://rrecrostov.com/official-representative-of-rostsmu/" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Contact Us - Official Representative Foreign Students" />
                    <meta property="og:description" content="A prospective student has to apply through our official admission partner: RREC (Reliable Russian Educational Consultants), Call 1800-572-5827 (Indian Students), +79604430042 (For International Students)" />
                    <meta property="og:url" content="https://rrecrostov.com/official-representative-of-rostsmu/" />
                    <meta property="og:site_name" content="Rostov State Medical University, Russia" />

                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>

                <section id="page-banner" class="pt-105 pb-110 bg_cover a1" data-overlay="8">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="page-banner-cont">
                                    <h2>Contact Us</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                                            <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <section id="contact-page" class="pt-90 pb-120 gray-bg">
                    <div class="container">
                        <div class="row align-items-center">
                            <div className='col-sm-12 text-center privacy-desc'>
                                <h2><strong>OUR INTERNATIONAL PARTNER</strong></h2>
                                <h3><strong>«Reliable Russian Educational Consultants Private Limited»</strong></h3>
                                <h5><strong>(Official Authorized Representative of Indian &amp; International Students)</strong></h5>

                            </div>
                        </div>

                        <div className='row'>

                            <div className='col-sm-6 bg-orange  text-center pt-50 pb-50 mt-50'>
                                <h3>Indian office</h3>
                                <p><strong>Head office:</strong> #608, 6th floor, Aggarwal Cyber Plaza-1<br /> Netaji Subhash Place, Pitampura, New Delhi -110034 (India)<br />
                                    <strong>Working Hours: </strong>10 am to 6 pm (Mon-Sat)<br />
                                    <strong>Office Landline:</strong> <a href="tel:+91-1143215827">+91-1143215827</a><br />
                                    <strong>For Indian Students:</strong> <a href="tel:+91-7042284508">+91-7042284508</a>, <a href="tel:+91-7042284509">+91-7042284509</a>, <strong>Toll-free: <a href="tel:79515371133">+7-9515371133</a></strong><br />
                                    <strong>For International Students:</strong> <a href="tel:+7-9515371133">+7-9515371133</a><br />
                                    <strong>E-mail:</strong> <a href="mailto:rrec.educonsultants@gmail.com">rrec.educonsultants@gmail.com</a>, <a href="mailto:contact@rrecrussia.com">contact@rrecrussia.com</a><br />
                                    <strong>Business Inquiries:</strong><a href="mailto:contact@rrec.com.ru">contact@rrec.com.ru</a></p>

                              
                            </div>

                            <div className='col-sm-6 bg-orange1  text-center pt-50 pb-50 mt-50'>
                                <h3>Russian Office</h3>

                                <p><strong>Head office:</strong> Suvorova Str., City: Rostov-on-Don, 344022, Russia<br />
                                    <strong>Working Hours:</strong> 11 am to 6 pm (Mon-Sat)<br />
                                    <strong>Office contact number:</strong> <a href="tel:+7-9515371133">+7-9515371133</a><br />
                                    <strong>E-mail:</strong> <a href="mailto:rrec.pvt.ltd@mail.ru">rrec.pvt.ltd@mail.ru</a>, <a href="mailto:contact@rrec.com.ru">contact@rrec.com.ru</a></p>




                                    <h3>Our Corporate office:</h3>

                                    <p>709-710, 7th floor <br />
PP Trade Center, Netaji Shubhash Place<br /> Pitampura, Pincode-110034
New Delhi (India)</p>

                            </div>


                        </div>


                        <div className='col-sm-12  pt-20 pb-20 mt-50'>

                            <p><strong>Worldwide presence:</strong> India, Russia, Nigeria, Ghana, Swaziland, South Africa, Malaysia, Botswana, UAE, Zambia, Cameroon, Saudi Arab, Lebanon, Namibia, Germany, Oman, Kuwait, Zimbabwe, Bangladesh, Jordan, Morocco.</p>
                        </div>

                        <div className='col-sm-12 bg-red text-center pt-50 pb-50 mt-50 text-white'>
                            <h3><strong>&rdquo;Dear students, we don&rsquo;t have any sub-agents who are working for us or authorized by us, except our branch offices. If any queries or need to confirm your admission, feel free to contact us at <a href="tel:917042284508">+917042284508</a>, <a href="tel:1800-572-5827">1800-572-5827</a>. <br />Beware of unauthorized agents.&rdquo; </strong></h3>

                        </div>
                    </div>
                </section>

            </main>

        )
    }

}

export default Official_representative_of_rostsmu;