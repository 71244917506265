import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';



export class Partner_with_us extends Component {
    render() {

        return (
            <main>
                <Helmet>
                    <title>Partner with us</title>
                    <meta name="description" content="Dear aspirants here is the online application for the 2024 academic year, interesting students can apply online to get direct admission in RostSMU, Russia." />
                    <meta name="robots" content="noindex, follow" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="article" />
                    <link rel="canonical" href="https://rrecrostov.com/partner-with-us" />
                    <meta property="og:title" content="Apply Now For 2024 - Rostov State Medical University" />
                    <meta property="og:description" content="Dear aspirants here is the online application for the 2024 academic year, interesting students can apply online to get direct admission in RostSMU, Russia." />
                    <meta property="og:url" content="https://rrecrostov.com/" />
                    <meta property="og:site_name" content="Rostov State Medical University, Russia" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>

                <section id="page-banner" class="pt-105 pb-110 bg_cover a1" data-overlay="8">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="page-banner-cont">
                                    <h2>Partner with us</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                                            <li class="breadcrumb-item active" aria-current="page">Partner with us</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <section id="contact-page" class="pt-90 pb-120 gray-bg">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-md-12 privacy-desc">
                                <h1>Become a Partner Associate,<br /> Get best Services from<br /> RREC Team are working with university since 15 years.</h1>
                                <p>Reliable Russian Educational Consultants (RREC Pvt Ltd) is the official authorized admission partner of Rostov State Medical University for Indians and International students. </p>
                                <p>For more queries, you can call us at 1800-572-5827 (Tollfree) / +91-70422-84508 (India),  +7-9515371133 (Russia)</p>
                                <h3>Comprehensive Services</h3>
                                <p> Assistance at each step of the study abroad lifecycle;</p>
                                <p>University selection, Selective Course details, application processes, admission procedure, fee structure, and visa processing. </p>
                                <h3>Professional Advice</h3>
                                <p>Increase your conversion rate through expert guidance from the RREC team with many years of experience in the Study MBBS abroad in Russia - Rostov state medical university.</p>
                                <h4>Official Email for Business Inquiries: <a href="mailto:contact@rrec.com.ru"> contact@rrec.com.ru</a></h4>
                                <div class="contact-from mt-30">
                                    <div class="main-form pt-45">
                                        <div class="section-title">
                                            <h2>Want to Become a Partner Associate?<br /> Submit your Query</h2>
                                        </div>

                                        <form action="https://app.todesign.in/react/rrecrostov.com/partner.php" name="f1" method="post" id="f1">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="name" type="text" placeholder="Your name" data-error="Name is required." required="required" />

                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="email" type="email" placeholder="Email" data-error="Valid email is required." required="required" />

                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="phone" type="text" placeholder="Phone" data-error="Phone is required." required="required" />

                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="company" type="text" placeholder="Company Name" data-error="Company Name is required." required="required" />

                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="website" type="utl" placeholder="Company Website" data-error="Company Website is required." required="required" />

                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="city" type="text" placeholder="City" data-error="City is required." required="required" />

                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="state" type="text" placeholder="State" data-error="State is required." required="required" />

                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="country" type="text" placeholder="Country" data-error="Country is required." required="required" />

                                                    </div>
                                                </div>

                                                <div class="col-md-12">
                                                    <div class="single-form form-group">
                                                        <textarea name="message" placeholder="Message" data-error="Please,leave us a message." required="required"></textarea>

                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="single-form">
                                                        <button type="submit" name="submit" id="submit" className="main-btn">Send
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>


            </main>




        )
    }

}

export default Partner_with_us;