import React, { Component } from 'react'

export class News extends Component {
  render() {

    return (
      <div className="row">
        <div className='col-sm-12'>
          <div className="marquee" id="mycrawler">
            <marquee scrollamount="3" direction="left" onMouseOver="stop()" onMouseOut="start();">	<span class="text-white"><span className="blink-text">Breaking News:</span> Dear students & parents, Admission is open for <span className="blink-text">2024</span> academic year. Get direct admission today with our Official Admission Partner of <span className="blink-text">Rostov State Medical University Reliable Russian Educational Consultants</span>. Beware from unauthorized agents.</span></marquee>
          </div>
        </div>
      </div>

    )
  }
}
export default News;