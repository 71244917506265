import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';


export class Admission_procedure_rostsmu extends Component {
    render() {

        return (
            <main>
                <Helmet>
                    <title>Admission Procedure - Rostov State Medical University, Russia</title>
                    <meta name="description" content="Admission procedure 2024: A prospective student has to apply through our official admission partner:(Reliable Russian Educational Consultants) Call 18005725827" />
                    <meta name="robots" content="index, follow" />
                    <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <link rel="canonical" href="https://rrecrostov.com/admission-procedure-rostsmu/" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Admission Procedure - Rostov State Medical University, Russia" />
                    <meta property="og:description" content="Admission procedure 2024: A prospective student has to apply through our official admission partner:(Reliable Russian Educational Consultants) Call 18005725827" />
                    <meta property="og:url" content="https://rrecrostov.com/" />
                    <meta property="og:site_name" content="Rostov State Medical University, Russia" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>

                <section id="page-banner" class="pt-105 pb-110 bg_cover a1" data-overlay="8">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="page-banner-cont">
                                    <h2>Admission Procedure </h2>
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                                            <li class="breadcrumb-item active" aria-current="page">Admission Procedure </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section class="pt-105 pb-110">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="privacy-desc">
                                    <h1>Admission Procedure for 2024-2025</h1>
                                    <p>Admissions to RostSMU are strictly based on Merit. Admission is a multistage process, please read the eligibility criteria and admission procedure below: </p>
                                    <h2>Eligibility</h2>
                                    <ul>
                                        <li>Successful completion of higher secondary school.</li>
                                        <li>Completion of tertiary education in case applying for Post-Graduate Education.</li>
                                        <li>The minimum requirement is an average of 65% marks in higher secondary school (<span lang="EN-US">each in chemistry, biology, and English language)</span>. </li>
                                        <li>Guarantee for the fulfillment of payment conditions.</li>
                                    </ul>
                                    <p>Admission Procedure: A prospective student has to apply through our official admission partner: RREC (Reliable Russian Educational Consultants)</p>
                                    <p>Step 1: Application form. Fill the application form accurately.</p>
                                    <p>Step 2: Documents required</p>
                                    <ul>
                                        <li> Scanned copy of the certificate of completion of higher secondary education  (should be translated into the Russian language)</li>
                                        <li> Scanned copy of your Passport (should be translated into the Russian language)</li>
                                        <li> Results of NEET (only for candidates from India)</li>
                                    </ul>
                                    <p>All documents must be sent tocontact@rrecrussia.com, contact@rrec.com.ru</p>
                                    <p>Step 4: Information Letter (Provisional Admission Letter).</p>
                                    <ul>
                                        <li>After fulfilling all the conditions and upon acceptance you will be provided with an Information letter (provisional admission letter) in electronic format upon request.</li>
                                    </ul>
                                    <p>Step 5: Confirmed Admission.</p>
                                    <ul>
                                        <li>As soon as your visa invitation will be ready, we will mail you your visa invitation or our official representative RREC Team will provide you with all the details about it. </li>
                                    </ul>
                                    <p>Step 6: After you receive the visa invitation, our official representative RREC Team will provide you visa from the Russian Embassy in Delhi (For Indian Students only). And for other countries, students will visit the Embassy (Consulate) of the Russian Federation to stamp their visa. More information on visa and registration, Official Contact Details; For Indian Students: 1800-572-5827 (Toll-free) | For Other Countries: +7-960-443-00-42</p>
                                    <p>Step 7: Welcome to Rostov-on-Don, Russia. (Capital City of South Russia)</p>
                                    <h3>Entrance Exam (After Arrival)</h3>
                                    <ul>
                                        <li>Chemistry</li>
                                        <li>Biology</li>
                                    </ul>
                                    <p>For more details contact with our representative: Official Contact Details; For Indian Students: <a href="tel:1800-572-5827">1800-572-5827</a> (Toll-free) | For Other Countries: <a href="tel:7-960-443-00-42">+7-960-443-00-42</a></p>




                                    <h3>Rostov State Medical University Students Reviews</h3>

                                    <div className='row pt-50'>
                                        <div className='col-sm-4'>

                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/N96O9LridA0" title="Best Medical University in Russia | Final Year Student Explains | Rostov State Medical University" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>

                                        <div className='col-sm-4'>

                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/cn1LvbvhNvU" title="Rostov State Medical University | Official | International Students Reviews" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>   </div>

                                    </div>

                                    <div className='col-sm-4'>

                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/Te5FvEdj0io" title="Real Truth of Rostov State Medical University | Chigwe Chansa, Zambia Student Explains" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>

        )
    }

}

export default Admission_procedure_rostsmu;
