import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';


export class Admission_procedure extends Component {
    render() {

        return (
            <main>
                <Helmet>
                    <title>Admission Procedure</title>
                    <meta name="description" content="Admission procedure 2024: A prospective student has to apply through our official admission partner:(Reliable Russian Educational Consultants) Call 18005725827" />
                    <meta name="robots" content="index, follow" />
                    <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <link rel="canonical" href="https://rrecrostov.com/admission-procedure/" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Admission Procedure - Rostov State Medical University, Russia" />
                    <meta property="og:description" content="Admission procedure 2024: A prospective student has to apply through our official admission partner:(Reliable Russian Educational Consultants) Call 18005725827" />
                    <meta property="og:url" content="https://rrecrostov.com/" />
                    <meta property="og:site_name" content="Rostov State Medical University, Russia" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>

                <section id="page-banner" class="pt-105 pb-110 bg_cover a1" data-overlay="8">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="page-banner-cont">
                                    <h2>Admission Procedure</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                                            <li class="breadcrumb-item active" aria-current="page">Admission Procedure</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <section class="pt-105 pb-110">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="privacy-desc">
                                    <h1>Rostov State Medical University admission fees &amp; duration</h1>
                                    <p>Rostov State Medical University’s fee structure is quite affordable for Indian students as compared to several western universities. Though the Rostov State Medical University fee structure remains the same for six years, however, it may rarely change 6-8% every year.</p>
                                    <ul>
                                        <li>Duration of MBBS in RostSMU: 6 years (Full Complete English Medium)</li>
                                        <li>Total Fees for MBBS in RostSMU: INR 19-20  lacs (Excluding Food)</li>
                                        <li>Note: The cost is approx. as it may fluctuate according to the current currency rate</li>
                                    </ul>

                                    <h2>Clearing NEXT exam or screening test</h2>
                                    <p>Foreign Medical Graduate Examination (FMGE) or NMC screening test which was an indispensable exam that needed to be cracked if you want to pursue your career or practice medicine in India after taking Rostov State Medical University admission and completed MBBS from RostSMU or any other Russian University. However, FMGE or NMC screening test is abolished now by the regulatory. Now students in the final year will prepare for NEXT (National Exit Test) instead, a single-window examination, and that will replace the NEET-PG exam &amp; NMC Exam (FMGE). It is going to be implemented by 2024. It means current students who are going to take admission for MBBS in RostSMU or abroad will have to take the NEXT exam. This test is essential and mandatory to pass in order to get the provisional or permanent registration licensing from the National Medical Commission of India. You will also be considered to be eligible for a one-year internship in India after clearing this exam.</p>
                                    <p>Important information: Just appearing in the NEXT exam does not mean you are qualified to get provisional or permanent registration from NMC in India, you need to pass it as well. You must be well-informed and aware of the eligible criteria, scheme, and pattern of the examination before filling the application form. To appear in the NEXT exam, the candidate must complete their primary medical qualification (MBBS) from NMC approved university in Russia.</p>

                                    <h2>Consult official representative to avoid fake agents</h2>
                                    <p>Undoubtedly, over the years, many unauthorized or unofficial agents have surged who try to take maximum advantage of the reputation and popularity of Rostov State Medical University. During the consultation for MBBS in Russia, you may confront several agents who claim to be an official representative of RostSMU however they are just creating false assumptions to trap you in. Under such circumstances, both students and parents must be aware of the fact that such agents may try to distract you with attractive schemes or several other misleading and unauthorized information regarding Rostov State Medical University admission. Here you need to be wise and clever. You should consult the appointed and official representative of RostSMU that is RREC (Reliable Russian Educational Consultants)</p>
                                    <ul>
                                        <li>Official Contact Details; For Indian Students: <a href="tel:1800-572-5827">1800-572-5827</a> (Toll-free), For Other Countries: <a href="tel:+7-960-443-00-42">+7-960-443-00-42</a>, Email: <a href="mailto:contact@rrecrussia.com">contact@rrecrussia.com</a>, <a href="mailto:contact@rrec.com.ru">contact@rrec.com.ru</a></li>
                                    </ul>
                                    <h3>Admission Procedure for 2024-2025</h3>
                                    <p>Admissions to RostSMU are strictly based on Merit. Admission is a multistage process, please read the eligibility criteria and admission procedure below: </p>

                                    <h4>Eligibility</h4>
                                    <ul>
                                        <li>Successful completion of higher secondary school.</li>
                                        <li>Completion of tertiary education in case applying for Post-Graduate Education.</li>
                                        <li>The minimum requirement is an average of 65% marks in higher secondary school (each in chemistry, biology, and English language). </li>
                                        <li>Guarantee for the fulfillment of payment conditions.</li>
                                    </ul>
                                    <p>Admission Procedure: A prospective student has to apply through our official admission partner: RREC (Reliable Russian Educational Consultants)</p>
                                    <p><strong>Step 1:</strong> Application form. Fill the application form accurately.</p>
                                    <p><strong>Step 2:</strong> Documents required</p>
                                    <ul>
                                        <li> Scanned copy of the certificate of completion of higher secondary education  (should be translated into the Russian language)</li>
                                        <li> Scanned copy of your Passport (should be translated into the Russian language)</li>
                                        <li>Results of NEET (only for candidates from India)</li>
                                    </ul>
                                    <h4>All documents must be sent to <a href="mailto:contact@rrecrussia.com">contact@rrecrussia.com</a>, <a href="mailto:contact@rrec.com.ru">contact@rrec.com.ru</a></h4>
                                    <p><strong>Step 4:</strong> Information Letter (Provisional Admission Letter).</p>
                                    <ul>
                                        <li>After fulfilling all the conditions and upon acceptance you will be provided with an Information letter (provisional admission letter) in electronic format upon request.</li>
                                    </ul>
                                    <p><strong>Step 5:</strong> Confirmed Admission.</p>
                                    <ul>
                                        <li>As soon as your visa invitation will be ready, we will mail you your visa invitation or our official representative RREC Team will provide you with all the details about it. </li>
                                    </ul>
                                    <p><strong>Step 6:</strong> After you receive the visa invitation, our official representative RREC Team will provide you visa from the Russian Embassy in Delhi (For Indian Students only). And for other countries, students will visit the Embassy (Consulate) of the Russian Federation to stamp their visa. More information on visa and registration, Official Contact Details; For Indian Students: 1800-572-5827 (Toll-free) | For Other Countries: +7-960-443-00-42</p>
                                    <p><strong>Step 7:</strong> Welcome to Rostov-on-Don, Russia. (Capital City of South Russia)</p>

                                    <h3>Entrance Exam (After Arrival)</h3>
                                    <ul>
                                        <li>Chemistry</li>
                                        <li>Biology</li>
                                    </ul>
                                    <div className='col-sm-12 bg-orange border-radius'>
                                        <p>For more details contact with our representative: Official Contact Details; For Indian Students: 1800-572-5827 (Toll-free) | For Other Countries: +7-960-443-00-42</p>
                                    </div>









                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>

        )
    }

}

export default Admission_procedure;