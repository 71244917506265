import React, { Component } from 'react';
import {createBrowserHistory} from 'history';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Logo from './Logo';

export class Menu extends Component {
    render() {
        return (
      
            <nav className="navbar navbar-expand-lg">
           <Logo />
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
            </button>

            <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                
                
                 <li className="nav-item"><a href={process.env.PUBLIC_URL + '/'}>HOME</a></li>
<li  className="nav-item"><a href={process.env.PUBLIC_URL + '/faculties-fees'}>FACULTIES &amp; FEES</a></li> 
<li className="nav-item"><a href={process.env.PUBLIC_URL + '/apply-now'}>APPLY NOW</a></li>
<li className="nav-item"><a href={process.env.PUBLIC_URL + '/official-representative-of-rostsmu'}>CONTACT US</a></li>
</ul>
</div>
  </nav>

    
            )
    }
}
export default Menu;