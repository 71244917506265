import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';


export class Reviews extends Component {
    render() {

        return (
            <main>
                <Helmet>
                    <title>Reviews</title>
                    <meta name="description" content="Admission procedure 2024: A prospective student has to apply through our official admission partner:(Reliable Russian Educational Consultants) Call 18005725827" />
                    <meta name="robots" content="index, follow" />
                    <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <link rel="canonical" href="https://rrecrostov.com/Reviews/" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Admission Procedure - Rostov State Medical University, Russia" />
                    <meta property="og:description" content="Admission procedure 2024: A prospective student has to apply through our official admission partner:(Reliable Russian Educational Consultants) Call 18005725827" />
                    <meta property="og:url" content="https://rrecrostov.com/" />
                    <meta property="og:site_name" content="Rostov State Medical University, Russia" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>

                <section id="page-banner" class="pt-105 pb-110 bg_cover a1" data-overlay="8">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="page-banner-cont">
                                    <h2>Reviews</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                                            <li class="breadcrumb-item active" aria-current="page">Reviews</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <section class="pt-105 pb-110">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="privacy-desc">
                                    <h2>RostSMU reviews</h2>
                                    <p>Several students who are pursuing or have completed their medical studies from the university provide their insight in the form of reviews, some of them are mentioned below:</p>
                                    <ul class="necessary-link mb-40">
                                        <li><i class="fal fa-check-square"></i>Training and teaching facility is optimum at RostSMU University. Teachers are very highly experienced and helpful. The teaching standard is also above par. The university environment is peaceful as well. I believe one cannot find many negatives here. Still, I believe as a student, you should also give your 100% to achieve your goal… B. Vikram, Andaman &amp; Nicobar Islands.</li>
                                        <li><i class="fal fa-check-square"></i>I am a fourth-year medical student at RostSMU. Professors and teachers are quite experienced having in-depth knowledge and expertise in their domain. It is so far so good experience for me. I have been exposed to a standout teaching methodology. Eventually, I realized that I had made the right decision by choosing RostSMU for MBBS… Manjesh Kumar, Bihar.</li>
                                        <li><i class="fal fa-check-square"></i>I am a 5th-year student. I always give value to the quality of education and expert teacher and professor. It is so because this is what matters in the long run. I like the teaching pattern which is quite engaging and progressive. It will make you competitive and prepared you for big things and challenge to come. Impressed so far… Ishan Sharma, Jammu.</li>
                                    </ul>
                                    <p>Rostov State Medical University is a highly sought-after university in Russia for doing MBBS. If you are looking for a medical university that can offer world-class education at an affordable budget you can always pick Rostov State Medical University in Russia. The RREC team, the official representative of RostSMU will always be there to provide all assistance and consultation for admission in RostSMU. Do not hesitate to contact us at www.rrecrostov.com for a free consultation.</p>
                                    <div className='col-sm-12 bg-orange border-radius'>
                                        <p>RostSMU Motto: «Время учиться быть профессионалом» &ldquo;It&rsquo;s a Time to be a Professional&rdquo;  (Study Medicine in Rostov State Medical University, Russia)</p>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/N96O9LridA0" title="Best Medical University in Russia | Final Year Student Explains | Rostov State Medical University" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>
                                        <div className='col-sm-6'>
                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/cn1LvbvhNvU" title="Rostov State Medical University | Official | International Students Reviews" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>
                                        <div className='col-sm-6'>
                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/Te5FvEdj0io" title="Real Truth of Rostov State Medical University | Chigwe Chansa, Zambia Student Explains" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>
                                        <div className='col-sm-6'>
                                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/jn7L47BPrjI" title="Incredible Rostov-on-Don | Beauty of Rostov in all Seasons | Rostov State Medical University" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>


                                    </div>




                                </div>

                            </div>
                        </div>
                    </div>
                </section>

            </main>

        )
    }

}

export default Reviews;