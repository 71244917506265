import React, { Component }  from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import SwiperCore, { FreeMode, Pagination, Autoplay } from "swiper";

export class Services extends Component {

    
    render() {

        return (
            <div class="row course-slide mt-30">

                

<div class="col-lg-4">
                    <div class="single-course">
                        <div class="thum">
                            <div class="image">
                                <img src="images/Rostov-state-medical-university.jpg" alt="Course" />
                            </div>
                        </div>
                        <div class="cont">
                            <a href={process.env.PUBLIC_URL + '/rostov-state-medical-university'}><h4>Rostov State Medical University</h4></a>
                            <p>When you dream big, it always requires a reliable platform, isn't it? If you are aiming for pursuing MBBS in Russia then Rostov State Medical University (RostSMU or RostGMU) will be an ideal place to be.</p>
                             <a href={process.env.PUBLIC_URL + '/rostov-state-medical-university'} className="main-btn">Read More</a>
                        </div>
                    </div>
                </div>
                
<div class="col-lg-4">
                    <div class="single-course">
                        <div class="thum">
                            <div class="image">
                                <img src="images/RostSMU-Today.jpg" alt="Course" />
                            </div>
                        </div>
                        <div class="cont">
                            <a href={process.env.PUBLIC_URL + '/rostsmu-today'}><h4>RostSMU Today</h4></a>
                            <p>Rostov State Medical University is the largest basic training and research center in Southern Russia. Over the 85 years of its existence, it has become a diversified complex with 10 faculties, Medical College,</p>
                             <a href={process.env.PUBLIC_URL + '/rostsmu-today'} className="main-btn">Read More</a>
                        </div>
                    </div>
                </div>
                
<div class="col-lg-4">
                    <div class="single-course">
                        <div class="thum">
                            <div class="image">
                                <img src="images/Admissions-RSMU.jpg" alt="Course" />
                            </div>
                        </div>
                        <div class="cont">
                            <a href={process.env.PUBLIC_URL + '/admission'}><h4>Admission</h4></a>
                            <p>Completing the admission process is easy and quick. The only thing you should remember regarding RostSMU admission in 2022 is the deadline. If you apply for admission within the deadline,</p>
                             <a href={process.env.PUBLIC_URL + '/admission'} className="main-btn">Read More</a>
                        </div>
                    </div>
                </div>
                
                
<div class="col-lg-4">
                    <div class="single-course">
                        <div class="thum">
                            <div class="image">
                                <img src="images/RostSMU-Hostels.jpg" alt="Course" />
                            </div>
                        </div>
                        <div class="cont">
                            <a href={process.env.PUBLIC_URL + '/hostel-facility'}><h4>Hostel Facility</h4></a>
                            <p>Accommodation or hostel facility is available in Rostov state medical university. The accommodation is based on a sharing basis where students are supposed to accommodate</p>
                             <a href={process.env.PUBLIC_URL + '/hostel-facility'} className="main-btn">Read More</a>
                        </div>
                    </div>
                </div>
                
 <div class="col-lg-4">
                    <div class="single-course">
                        <div class="thum">
                            <div class="image">
                                <img src="images/Adnission-duration-fees-rostsmu.jpg" alt="Course" />
                            </div>
                        </div>
                        <div class="cont">
                            <a href={process.env.PUBLIC_URL + '/admission-procedure'}><h4>Admission fees &amp; Duration</h4></a>
                            <p>Rostov State Medical University's fee structure is quite affordable for Indian students as compared to several western universities. Though the Rostov State Medical University fee structure</p>
                             <a href={process.env.PUBLIC_URL + '/admission-procedure'} className="main-btn">Read More</a>
                        </div>
                    </div>
                </div>
                
<div class="col-lg-4">
                    <div class="single-course">
                        <div class="thum">
                            <div class="image">
                                <img src="images/Students-Reviews.jpg" alt="Course" />
                            </div>
                        </div>
                        <div class="cont">
                            <a href={process.env.PUBLIC_URL + '/reviews'}><h4>RostSMU reviews</h4></a>
                            <p>Several students who are pursuing or have completed their medical studies from the university provide their insight in the form of reviews, some of them are mentioned.</p>
                             <a href={process.env.PUBLIC_URL + '/reviews'} className="main-btn">Read More</a>
                        </div>
                    </div>
                </div>   


                <div class="col-lg-4">
                    <div class="single-course">
                        <div class="thum">
                            <div class="image">
                                <img src="images/Indian-Food-available-in-campus.jpg" alt="Course" />
                            </div>
                        </div>
                        <div class="cont">
                            <a href={process.env.PUBLIC_URL + '/reviews'}><h4>Indian Mess Available in campus</h4></a>
                            <p><strong>Dear aspirants,</strong><br />
Indian Mess Available for Indian students in campus only with pure veg and non veg menu.<br /> 
<strong>"7 days open (9am -10 pm)<br /> 
(Lunch and Dinner)"</strong><br />
Food is prepared by professional Indian cooks.</p>
                           
                            </div>
                    </div>
                </div>                  
              

</div>

              
  
   
            )
        }
    }
export default Services;