import React, { Component }  from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import SwiperCore, { FreeMode, Pagination, Autoplay } from "swiper";


export class Clients extends Component {

    
    render() {
       
        return (
            <div class="row testimonial-slide mt-40">

<div class="col-lg-6">
<div class="single-testimonial">
<div class="testimonial-thum">
<img src="images/c1.jpg" alt="Testimonial" />
<div class="quote"><i class="fa fa-quote-right"></i></div>
</div>
<div class="testimonial-cont">
<p>Studying Medicine at Rostov State Medical University is the best decision of my life. During...</p>
<h6>Dr. Rakesh Yadav</h6>
<span>Graduate of RostSMU</span>
</div>
</div>
</div>

<div class="col-lg-6">
<div class="single-testimonial">
<div class="testimonial-thum">
<img src="images/c2.jpg" alt="Testimonial" /> 
<div class="quote"><i class="fa fa-quote-right"></i></div>
</div>
<div class="testimonial-cont">
<p>It was my dream to find the best college and best study in Russia to...</p>
<h6>Steffi Philips</h6>
</div>
</div>
</div>


<div class="col-lg-6">
<div class="single-testimonial">
<div class="testimonial-thum">
<img src="images/c3.jpg" alt="Testimonial" />
<div class="quote"><i class="fa fa-quote-right"></i></div>
</div>
<div class="testimonial-cont">
 <p>This is a great opportunity I had in my life. The best education is given...</p>
<h6>Dr. Devashish Sharma</h6>
<span>Graduate of RostSMU</span>
</div>
</div>
</div>


<div class="col-lg-6">
<div class="single-testimonial">
<div class="testimonial-thum">
<img src="images/c4.jpg" alt="Testimonial" />
<div class="quote"><i class="fa fa-quote-right"></i></div>
</div>
<div class="testimonial-cont">
 <p>Hi everyone, This is a great opportunity I had in my life. It was a...</p>
<h6>Ishan Sharma</h6>
</div>
</div>
</div>



<div class="col-lg-6">
<div class="single-testimonial">
<div class="testimonial-thum">
<img src="images/c5.jpg" alt="Testimonial" />
<div class="quote"><i class="fa fa-quote-right"></i></div>
</div>
<div class="testimonial-cont">
 <p>I am really happy about the work which the RREC team has done for me...</p>
<h6>Vikram B.</h6>
</div>
</div>
</div>


<div class="col-lg-6">
<div class="single-testimonial">
<div class="testimonial-thum">
<img src="images/c6.jpg" alt="Testimonial" />
<div class="quote"><i class="fa fa-quote-right"></i></div>
</div>
<div class="testimonial-cont">
   <p>Hello everyone, Firstly I would like to thank, Dr. Abhineet Sir. Basically, I don&#039;t have...</p>
<h6>Ashish Painuly</h6>
</div>
</div>
</div>


<div class="col-lg-6">
<div class="single-testimonial">
<div class="testimonial-thum">
<img src="images/c7.jpg" alt="Testimonial" />
<div class="quote"><i class="fa fa-quote-right"></i></div>
</div>
<div class="testimonial-cont">
   <p>Hello everyone, I just wanted to say thank you to RREC Team with my medical...</p>
<h6>Sakshi</h6>
</div>
</div>
</div>


<div class="col-lg-6">
<div class="single-testimonial">
<div class="testimonial-thum">
<img src="images/c8.jpg" alt="Testimonial" />
<div class="quote"><i class="fa fa-quote-right"></i></div>
</div>
<div class="testimonial-cont">
  <p>Because of RostSMU, I am here in my profession as a doctor. I was very...</p>
<h6>Dr. Mayur Dudhat</h6>
<span>Graduate of RostSMU</span>
</div>
</div>
</div>


<div class="col-lg-6">
<div class="single-testimonial">
<div class="testimonial-thum">
<img src="images/c9.jpg" alt="Testimonial" />
<div class="quote"><i class="fa fa-quote-right"></i></div>
</div>
<div class="testimonial-cont">
  <p>I am very glad that I got admission to RostSMU through the university&#039;s official representative...</p>
<h6>Dr. Ashish Dobariya</h6>
<span>Graduate of RostSMU</span>
</div>
</div>
</div>


<div class="col-lg-6">
<div class="single-testimonial">
<div class="testimonial-thum">
<img src="images/c10.jpg" alt="Testimonial" />
<div class="quote"><i class="fa fa-quote-right"></i></div>
</div>
<div class="testimonial-cont">
 <p>Hello, I am Brijesh Kumar and I want to share my new experience with my...</p>
<h6>Brijesh Bimal Singh</h6>
</div>
</div>
</div>


<div class="col-lg-6">
<div class="single-testimonial">
<div class="testimonial-thum">
<img src="images/c11.jpg" alt="Testimonial" />
<div class="quote"><i class="fa fa-quote-right"></i></div>
</div>
<div class="testimonial-cont">
 <p>Hello everyone,  I am Iti Verma, currently studying Medicine at Rostov state medical university, Russia....</p>
<h6>Iti Verma</h6>
</div>
</div>
</div>


<div class="col-lg-6">
<div class="single-testimonial">
<div class="testimonial-thum">
<img src="images/c12.jpg" alt="Testimonial" />
<div class="quote"><i class="fa fa-quote-right"></i></div>
</div>
<div class="testimonial-cont">
 <p>Hello, My name is Swapnil Ramesh Ovhal. I came to know about Rostov State Medical...</p>
<h6>Swapnil Ovhal</h6>
</div>
</div>
</div>

         </div>
          
           
            )
        }
    }
export default Clients;